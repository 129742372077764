<template>
  <v-container>
    <v-row v-for="(v, i) in items" :key="i" class="nth-row">
      <v-btn
        fab
        color="error"
        absolute
        right
        v-show="removeShow"
        @click="removeItem(i)"
        x-small
        ><v-icon>mdi-minus</v-icon></v-btn
      >
      <condition-tree-item
        :values="v"
        v-on:update-items="(d) => updateItem(i, d)"
        :or="i != 0"
        :only-sensor="true"
      ></condition-tree-item>
    </v-row>

    <v-row class="align-center">
      <v-spacer></v-spacer>
      <v-btn color="grey" text @click="removeShow = !removeShow">{{
        $t("common.delete")
      }}</v-btn>
      <v-btn color="success" class="ml-3" @click="items.push({})" text>
        {{ $t("common.add") }}<v-icon class="ml-2">mdi-plus</v-icon>
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import ConditionTreeItem from "./ConditionTreeItem.vue";

export default {
  name: "ConditionTree",

  props: {
    modelItem: {
      required: null,
    },
  },

  model: {
    prop: "modelItem",
    event: "model-change",
  },

  data() {
    return {
      items: [],

      removeShow: false,
    };
  },

  methods: {
    removeItem(i) {
      this.items.splice(i, 1);
    },

    updateItem(i, data) {
      this.items[i] = data;
      this.$emit("model-change", this.items);
    },
  },

  computed: {},

  created() {
    this.items = this.modelItem;
    if (this.modelItem && this.modelItem.length <= 0 && this.items.length <= 0)
      this.items.push({});
  },

  components: {
    ConditionTreeItem,
  },
};
</script>

<style>
.nth-row {
  color: #000;
}

.nth-row:nth-child(2n + 1) {
  background: #e6e6e6;
}

.nth-row:nth-child(2n) {
  background: #ececec;
}

.nth-row:first-child {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.nth-row:nth-last-child(2) {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
</style>