<template>
  <v-container>
    <h3 class="text-left headline ml-3">
      {{ or ? (items.value && items.value.type == 3 ? "and" : "or") : "if" }}
    </h3>
    <v-row class="d-flex align-center colorize-nth">
      <v-col cols="12" md="4" sm="12">
        <condition-tree-item-value
          :item-value="items.device"
          v-on:value-update="(v) => updateItems('device', v)"
          :only-sensor="true"
        />
      </v-col>

      <v-col cols="12" md="5" sm="12">
        <operation-selector
          v-model="items.op"
          v-on:update-op="(v) => updateItems('op', v)"
        />
      </v-col>

      <v-col cols="12" md="3" sm="12">
        <condition-tree-item-value
          :item-value="items.value"
          v-on:value-update="(v) => updateItems('value', v)"
          :disable-sensor="true"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ConditionTreeItemValue from "./ConditionTreeItemValue.vue";
import OperationSelector from "./OperationSelector.vue";

export default {
  name: "ConditionTreeItem",

  props: {
    values: {
      default: null,
    },

    or: {
      default: true,
    },
  },

  data() {
    return {
      op: ">",

      items: {},
    };
  },

  computed: {},

  methods: {
    updateItems(i, item) {
      this.items[i] = item;
      this.$emit("update-items", this.items);
    },
  },

  created() {
    if (this.values) {
      this.items = this.values;
    }
  },

  watch: {
    items(v) {
      this.$emit("update-items", v);
    },
  },

  components: {
    ConditionTreeItemValue,
    OperationSelector,
  },
};
</script>
