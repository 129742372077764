<template>
  <div class="d-inline-flex justify-space-around">
    <div
      v-for="op in ops"
      :key="op.value"
      :class="`op-container ${localOp == op.value ? 'op-selected' : ''}`"
      @click="localOp = op.value"
    >
      {{ op.value }}
    </div>
  </div>
</template>

<script>
export default {
  name: "OperationSelector",

  props: {
    operation: {
      default: ">",
    },
  },

  model: {
    prop: "operation",
    event: "update-op",
  },

  data() {
    return {
      ops: [
        { value: "<", name: this.$t("conditionTree.ops.less") },
        { value: "=", name: this.$t("conditionTree.ops.equal") },
        { value: ">", name: this.$t("conditionTree.ops.greater") },
        {
          value: "<=",
          name: this.$t("conditionTree.ops.lessOrEqual"),
        },
        {
          value: ">=",
          name: this.$t("conditionTree.ops.greaterOrEqual"),
        },
        {
          value: "!=",
          name: this.$t("conditionTree.ops.notEqual"),
        },
      ],
    };
  },

  computed: {
    localOp: {
      get: function () {
        return this.operation;
      },

      set: function (v) {
        this.$emit("update-op", v);
      },
    },
  },

  created() {
    this.$emit("update-op", this.operation);
  },
};
</script>

<style>
.op-container {
  font-size: 1.2rem;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid black;
  margin: 0.3rem;
  background: #4884bd2f;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: bolder;
}

.op-selected {
  background: #324149 !important;
  color: white;
}
</style>