<template>
  <v-container>
    <!-- Select type open dialog to select the type -->
    <v-container v-if="!type">
      <v-btn
        color="primary"
        outlined
        rounded
        @click="selectDialog = true"
        v-if="value == null || value.type == null"
      >
        <v-icon left color="primary lighten-2">mdi-plus</v-icon>
        {{ $t("conditionTree.selectValue") }}
      </v-btn>
      <v-btn
        color="green darken-2"
        outlined
        rounded
        @click="openEdit"
        v-else-if="value != null && value.type != null"
      >
        {{ label }}
      </v-btn>
    </v-container>

    <condition-tree-item-value-form
      v-model="selectDialog"
      :input="value.value"
      :time="value.value"
      :device="value.device"
      :type="value.type"
      v-on:update-value="setValue"
      :disable-sensor="disableSensor"
      :only-sensor="onlySensor"
    />
  </v-container>
</template>

<script>
import CsharpEnum from "@/_helpers/CsharpEnum";
import ConditionTreeItemValueForm from "./ConditionTreeItemValueForm.vue";

export default {
  components: { ConditionTreeItemValueForm },
  name: "ConditionTreeItemValue",

  props: {
    itemValue: {
      required: null,
    },

    disableSensor: {
      default: false,
    },

    onlySensor: {
      default: false,
    },
  },

  model: {
    prop: "itemValue",
    event: "value-update",
  },

  data() {
    return {
      type: null,
      selectDialog: false,
      valid: false,
      value: {},
    };
  },

  computed: {
    localValue: {
      get: function () {
        return this.itemValue;
      },

      set: function (v) {
        this.$emit("value-update", v);
      },
    },

    types() {
      return CsharpEnum.ConditionTypes;
    },

    label() {
      if (this.value.type == 0) {
        return `${this.truncate(
          this.value.name ? this.value.name : this.value.deveui
        )} - ${this.truncate(
          this.value.keyLabel ? this.value.keyLabel : this.value.key
        )}`;
      }

      if (
        this.value.type == 1 ||
        this.value.type == 2 ||
        this.value.type == 3 ||
        this.value.type == 4
      )
        return this.value.value;

      return "";
    },
  },

  methods: {
    openEdit() {
      this.selectDialog = true;
    },

    truncate(s) {
      if (!s) return s;
      if (s.length <= 16) return s;

      return s.slice(0, 16) + "...";
    },

    setValue(v) {
      this.value = v;
      this.localValue = this.value;
    },
  },

  async created() {
    if (this.itemValue && this.itemValue.type != undefined) {
      this.value = this.itemValue;
    }
  },
};
</script>