<template>
  <v-dialog v-model="localDialog" max-width="1250">
    <v-card>
      <v-form
        :lazy-validation="true"
        @submit.prevent="handleSubmit"
        v-model="valid"
      >
        <v-card-title>{{
          $t("conditionTree.selectValueTypeTitle")
        }}</v-card-title>

        <v-card-text>
          <v-row v-if="!onlySensor">
            <v-col :cols="12" :md="6" :sm="12" v-for="(v, i) in types" :key="i">
              <selection-sheet
                v-model="localType"
                :item="v"
                color="primary"
                item-key="value"
                item-label="text"
                :icon-key="v.icon"
                :disable="
                  (disableSensor && v.value == 0) ||
                  (onlySensor && v.value != 0)
                "
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text v-if="localType == 0">
          <device-selection
            :tag="deveui"
            :tag-key="key"
            v-on:update-tag="(v) => (deveui = v)"
            v-on:update-name="(v) => (tagName = v)"
            v-on:update-tag-key="(v) => (key = v)"
            v-on:update-tag-key-label="(v) => (keyLabel = v)"
          />
        </v-card-text>

        <v-card-text v-else-if="localType == 1">
          <v-text-field
            v-model="inputValue"
            prepend-icon="mdi-text"
            :placeholder="$t('conditionTree.textInputPlaceholder')"
          ></v-text-field>
        </v-card-text>

        <v-card-text v-else-if="localType == 2">
          <v-text-field
            type="number"
            v-model="inputValue"
            prepend-icon="mdi-text"
            :placeholder="$t('conditionTree.numberInputPlaceholder')"
          ></v-text-field>
        </v-card-text>

        <v-card-text v-else-if="localType == 3">
          <digital-time-picker v-model="timeValue" />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click="closeDialog">{{
            $t("common.close")
          }}</v-btn>
          <v-btn
            color="primary"
            text
            type="submit"
            x-large
            :disabled="submitDisabled"
          >
            <v-icon left>mdi-plus-circle</v-icon>
            {{ $t("common.add") }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import CsharpEnum from "@/_helpers/CsharpEnum";
import SelectionSheet from "../common/SelectionSheet.vue";
import DeviceSelection from "../common/DeviceSelection.vue";
import DigitalTimePicker from "../common/DigitalTimePicker.vue";

export default {
  name: "ConditionTreeItemValueForm",

  props: {
    dialog: {
      default: false,
    },

    input: {
      default: null,
    },

    time: {
      default: null,
    },

    device: {
      default: null,
    },

    type: {
      default: null,
    },

    disableSensor: {
      default: false,
    },

    onlySensor: {
      default: false,
    },
  },

  model: {
    prop: "dialog",
    event: "update-model",
  },

  data() {
    return {
      valid: false,
      localType: null,
      inputValue: null,
      timeValue: null,
      deveui: null,
      key: null,
      keyLabel: null,
      tagName: null,
    };
  },

  computed: {
    localDialog: {
      get: function () {
        return this.dialog;
      },

      set: function (v) {
        this.$emit("update-model", v);
      },
    },

    localDevice() {
      return {
        deveui: this.deveui,
        key: this.key,
        keyLabel: this.keyLabel,
        name: this.tagName,
        type: 0,
      };
    },

    types() {
      var result = CsharpEnum.ConditionTypes;
      delete result.SENSORVALUE;
      return result;
    },

    submitDisabled() {
      if (this.localType == 4) return false;

      if (
        this.localType == 0 &&
        this.localDevice.deveui != null &&
        this.localDevice.key != null
      )
        return false;

      if (
        (this.localType == 1 || this.localType == 2) &&
        this.inputValue != null
      )
        return false;

      if (this.localType == 3 && this.timeValue != null) return false;

      return true;
    },
  },

  methods: {
    handleSubmit() {
      if (this.localType == 0) this.$emit("update-value", this.localDevice);
      else if (this.localType == 1 || this.localType == 2)
        this.$emit("update-value", {
          type: this.localType,
          value: this.inputValue,
        });
      else if (this.localType == 3)
        this.$emit("update-value", {
          type: this.localType,
          value: this.timeValue,
        });
      else if (this.localType == 4)
        this.$emit("update-value", {
          type: this.localType,
          value: "No Value",
        });
      else this.$emit("update-value", {});

      this.valid = false;
      this.tagName = null;
      this.keyLabel = null;
      this.localDialog = false;

      this.resetProperties();
    },

    resetProperties() {
      this.deveui = null;
      this.key = null;
      this.timeValue = null;
      this.inputValue = null;
      this.localType = null;
    },

    setParentProperties() {
      // We reset the properties for reactivity
      this.resetProperties();

      var d = this.device ? this.device : { deveui: null, key: null };
      this.localType = this.type;
      this.deveui = d.deveui;
      this.key = d.key;
      this.timeValue = this.time;
      this.inputValue = this.input;

      if (this.onlySensor) {
        this.localType = 0;
      }
    },

    closeDialog() {
      this.resetProperties();
      this.localDialog = false;
    },
  },

  watch: {
    localType(v, ov) {
      if (v != ov) {
        this.deveui = null;
        this.key = null;
        this.inputValue = null;
        this.timeValue = null;
      }
    },

    dialog() {
      this.setParentProperties();
    },
  },

  mounted() {
    this.setParentProperties();
  },

  components: { SelectionSheet, DeviceSelection, DigitalTimePicker },
};
</script>

