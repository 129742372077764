<template>
  <v-container>
    <v-row class="d-flex justify-center align-center">
      <v-autocomplete
        :label="$t('common.hours')"
        v-model="localHour"
        :items="hours"
        style="
          width: 65px;
          max-width: 70px;
          font-size: 1.5rem;
          font-weight: 600;
          margin-right: 1rem;
        "
      ></v-autocomplete>

      <h3 class="d-flex justify-center align-center">:</h3>

      <v-autocomplete
        :label="$t('common.mintues')"
        v-model="localMinute"
        :items="mintues"
        style="
          width: 65px;
          max-width: 70px;
          font-size: 1.5rem;
          font-weight: 600;
          margin-left: 1rem;
        "
      ></v-autocomplete>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "DigitalTimePicker",

  props: {
    time: {
      default: null,
    },
  },

  model: {
    prop: "time",
    event: "update-time",
  },

  data() {
    return {
      localHour: "00",
      localMinute: "00",
    };
  },

  computed: {
    hours() {
      return [...Array(24).keys()].map((e) =>
        e >= 10 ? e.toString() : "0" + e.toString()
      );
    },

    mintues() {
      return [...Array(60).keys()].map((e) =>
        e >= 10 ? e.toString() : "0" + e.toString()
      );
    },

    firstTen() {
      var result = [];
      for (var i of Array(10).keys()) result.push("0" + i);
      return result;
    },
  },

  watch: {
    localHour(v) {
      this.$emit("update-time", `${v}:${this.localMinute}`);
    },

    localMinute(v) {
      this.$emit("update-time", `${this.localHour}:${v}`);
    },

    time(v) {
      if (v != null) {
        var sp = v.split(":");
        this.localHour = sp[0];
        this.localMinute = sp[1];
      }
    },
  },

  created() {
    if (this.time != null) {
      var sp = this.time.split(":");
      this.localHour = sp[0];
      this.localMinute = sp[1];
    }
  },
};
</script>